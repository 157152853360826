export function FormatDate(date: Date) {
  if (!date) {
    return '';
  }
  return new Date(date).toLocaleDateString('pt-BR')
}

export function FormatDateAdvisor(date: string) {
    if (!date) return ""
    let partsDate = date.split("-");
    let formattedDate = new Date(parseInt(partsDate[2], 10), parseInt(partsDate[1], 10) - 1, parseInt(partsDate[0], 10));
    return new Date(formattedDate).toLocaleDateString('pt-BR')
}

export function FormatHour(date: Date) {
    return new Date(date).toLocaleDateString('pt-BR', { hour: '2-digit', minute: '2-digit' })
}
